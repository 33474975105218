<template>
  <div v-if="EditItem">
    <el-dialog
      :title="EditItem.title1"
      :visible.sync="dialogshow"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      @close="closeDialog"
      width="800px" custom-class="cus_dialog"
    >
      <div>
        <el-form :model="EditItem" ref="EditItem" label-width="120px" v-if="EditItem">
          <el-row style="padding-top: 10px;">
            <el-col :span="10">
              <el-form-item label="班级">
                <el-select
                  v-model="EditItem.class_id"
                  filterable
                  placeholder="请选择班级"
                  style="width:180px"
                >
                  <el-option
                    v-for="(item,idx) in options"
                    :key="idx"
                    :label="item.name+(item.type=='bj'?'[班级]':'[小组]')"
                    :value="item.id"
                    filterable:true
                    :disabled="disabled"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="教师姓名">
                <el-input v-model="EditItem.teacher_name" disabled style="width:180px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="padding-top: 10px;">
            <el-col :span="10">
              <el-form-item label="教师工号">
                <el-input v-model="EditItem.teacher_code" disabled style="width:180px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="标题">
                <el-input v-model="EditItem.title" :disabled="disabled" style="width:180px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="内容">
            <el-col :span="22">
              <div style="line-height: initial; width: 100%; ">
                <!-- <el-input v-model="EditItem.plan_desc" :disabled="disabled" type="textarea" :autosize="{ minRows: 10 }" :show-word-limit="true" :maxlength="10000" -->
                  <!-- style="width:100%;"></el-input> -->
                <UEditor ref="ue" @ready="editorReady" style="width: 100%; "></UEditor>
              </div>
            </el-col>
          </el-form-item>
          <el-row style="padding-top: 10px;">
            <el-col :span="10">
              <el-form-item label="时间">
                <el-date-picker
                  v-model="EditItem.plan_time"
                  :disabled="disabled"
                  style="width:180px"
                  align="right"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="排序号">
                <el-input-number
                  v-model="EditItem.sort"
                  :disabled="disabled"
                  controls-position="right"
                  @change="handleChange"
                  style="width:180px"
                ></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <el-row>
          <el-col
            :span="22"
            style="text-align: right;padding-left: 10px;"
            v-if="$store.state.teacher.id == EditItem.teacher_id || EditItem.id == 0"
          >
            <el-button size="small" icon="el-icon-edit" type="primary" @click="submitForm()">提交</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UEditor from "../notify/ueditor.vue";
import util from "../../utils/util.js";
export default {
  components: { UEditor },
  props: ["id"],
  data() {
    return {
      thisTeacherid: 0,
      dialogshow: true,
      disabled: true,
      editor: null,
      EditItem: {
        id: 0,
        title: "",
        class_name: "",
        teacher_id: 0,
        teacher_name: "",
        teacher_code: "",
        plan_desc: "",
        plan_time: ""
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            }
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            }
          }
        ]
      },
      options: []
    };
  },
  mounted() {
    this.$$parent(this, "EditItem").then(res => {
      if (res.id == 0) {
        res.teacher_id = this.$store.state.teacher.id;
        res.teacher_name = this.$store.state.teacher.username;
        res.teacher_code = this.$store.state.teacher.loginname;
        res.plan_time = util.formatDate(new Date());
        this.disabled = false;
        this.initTeacherInfo(0);
      } else {
        if (this.$store.state.teacher.id == res.teacher_id) {
          this.disabled = false;
          this.initTeacherInfo(res.class_id);
        } else {
          //修改不是自己的
          this.options = [{ id: res.class_id, name: res.class_name }];
          this.EditItem.class_id = this.options[0].id;
        }
        setTimeout(() => {
          this.editor.setContent(this.EditItem.plan_desc);
        }, 500);
      }
      this.EditItem = res;
    });
  },
  methods: {
    handleChange(value) {
      console.log(value);
    },
    initTeacherInfo(class_id) {
      this.$http.post("/api/get_tch_cls_data").then(res => {
        this.options = [].concat(res.data.bj_list).concat(res.data.sxxz_list);
        if (class_id > 0) {
          this.EditItem.class_id = class_id;
        } else {
          this.EditItem.class_id = this.options[0].id;
        }
      });
    },
    closeDialog() {
      this.$$parent(this, "dialogshow", false);
    },
    submitForm(st) {
      let data = JSON.parse(JSON.stringify(this.EditItem));
      if (data.plan_desc.length < 100) {
        this.$message.error("内容必须大于100字!");
        return false;
      }
      if (!data.title.trim()) {
        this.$message.error("标题不能为空!");
        return false;
      }
      this.$http.post("/api/teacher_plan_edit", data).then(res => {
        this.$message({
          type: "success",
          message: "提交成功"
        });
        this.$$parent(this, "getList");
        this.$$parent(this, "dialogshow", false);
      });
    },
    editorReady(editorInstance) {
      editorInstance.setContent(this.EditItem.plan_desc);
      editorInstance.addListener("contentChange", () => {
        this.EditItem.plan_desc = editorInstance.getContent();
      });
      this.editor = editorInstance;
    }
  }
};
</script>

<style scoped>
.imgaddbtn {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dotted #ccc;
  font-size: 30px;
  cursor: pointer;
}

.imgremove {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;

  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  color: #ccc;
}

.imgremove:hover {
  color: orange;
}
</style>
